<template>
    <!-- {{ ruleList }} -->
    <div class="rules-detail">
        <div class="rule-left" @click="changeIsOr" :class="{ active: ruleList.connector === 'OR' }"
            v-if="ruleList.conditions.length > 1">
            <div class="link">
                <span class="q"> {{ ruleList.connector === 'OR' ? "或" : "且" }} </span>
            </div>
            <div class="rule-left-icon">
                <el-icon :size="12" color="#fff" class="icon">
                    <i class="iconfont icon-qiehuan"></i>
                </el-icon>
            </div>
        </div>
        <div class="rule-right">
            <template v-for="(first, index) in ruleList.conditions">
                <div class="rule-content">
                    <div class="rule-content-detail">
                        <div class="rule-left" v-if="first.conditions.length > 1" @click="changeArrIsOr(first)" :class="{
                            active: first.connector === 'OR',
                            connectorColor: ruleList.connector === 'OR',
                            isShowLine: ruleList.conditions.length == 1,
                        }">
                            <div class="link">
                                <span class="q"> {{ first.connector === 'OR' ? "或" : "且" }} </span>
                            </div>
                            <div class="rule-left-icon">
                                <el-icon :size="12" color="#fff" class="icon">
                                    <i class="iconfont icon-qiehuan"></i>
                                </el-icon>
                            </div>
                        </div>
                        <div class="r">
                            <ul>
                                <template v-for="(second, secondIndex) in first.conditions">
                                    <li :class="{
                                        isfirstOrColor: first.connector === 'OR',
                                        isShowFirstLine: first.conditions.length == 1,
                                        isShowAllLine:
                                            first.conditions.length == 1 && ruleList.conditions.length > 1,
                                        isAllOrColor: ruleList.connector === 'OR',
                                    }">
                                        <div class="space">
                                            <el-select v-model="second.field" placeholder="请选择执行规则条件" size="small"
                                                @change="checkRuleAndGenerateDesc">
                                                <el-option v-for="(item, index) in options.conditions" :key="index"
                                                    :label="item.name" :value="item.value">
                                                    <el-row type="flex" justify="space-between" align="middle">
                                                        <span>{{ item.name }}</span>
                                                        <el-tooltip class="item" effect="light" content=""
                                                            placement="top">
                                                            <template #content>
                                                                <span>{{ conditionMap[item.name] }}</span>
                                                            </template>
                                                            <i class="el-icon-warning-outline"
                                                                v-show="conditionMap[item.name]"></i>
                                                        </el-tooltip>
                                                    </el-row>
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div class="space">
                                            <el-select v-model="second.range" placeholder="请选择统计范围" size="small"
                                                @change="checkRuleAndGenerateDesc">
                                                <el-option v-for="(item, index) in options.ranges" :key="index"
                                                    :label="item.name" :value="item.value" />
                                            </el-select>
                                        </div>
                                        <div class="space">
                                            <el-select v-model="second.operator" placeholder="请选择" size="small"
                                                @change="checkRuleAndGenerateDesc">
                                                <el-option v-for="(item, index) in options.operators" :key="index"
                                                    :label="item.name" :value="item.value" />
                                            </el-select>
                                        </div>
                                        <div class="space">
                                            <el-input v-model="second.value" size="small" placeholder="请输入数值"
                                                @input="checkRuleAndGenerateDesc" />
                                        </div>
                                        <div class="space">
                                            <div class="operation" v-if="first.conditions.length == secondIndex + 1"
                                                @click="addRule(first.conditions)">
                                                <el-icon :size="12" color="#2b82ff" class="icon">
                                                    <i class="iconfont icon-jiatiaojian"></i>
                                                </el-icon>
                                            </div>
                                            <div class="operation" v-if="first.conditions.length > 1 || index != 0"
                                                @click="delRule(first.conditions, secondIndex, index)">
                                                <el-icon :size="12" color="#2b82ff" class="icon">
                                                    <i class="iconfont icon-jiantiaojian"></i>
                                                </el-icon>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <div style="display: flex; justify-content: flex-end">
        <el-button @click="addGroup" size="small" plain style="margin-right: 0" icon="el-icon-plus"
            class="add-group-button">加组</el-button>
    </div>
</template>

<script setup>
import { computed, watch } from 'vue';

const props = defineProps(["ruleList", "options", "level", "type"]);
const emit = defineEmits(["update:ruleList", "desc"]);
defineOptions({
    name: "rulerCom",
    inheritAttrs: false,
});

const typeMap = {
    1: "关闭广告系列",
    2: "开启广告系列",
};

const conditionMap = {
    CTR: "点击率 SUM(clicks)/SUM(impressions)*100",
    CPC: "单次点击成本 SUM(spent)/SUM(clicks)",
    CPM: "千次展示成本 SUM(spent)/SUM(impressions)*1000",
    ROAS: "投资回报率 购买价值/广告花费，受归因设置影响",
}

const optionsMap = computed(() => {
    // 将options.conditions、options.ranges、options.operators的name作为value，value作为key
    const map = {
        conditions: {},
        ranges: {},
        operators: {},
    };
    props.options.conditions.forEach((item) => {
        map.conditions[item.value] = item.name;
    });
    props.options.ranges.forEach((item) => {
        map.ranges[item.value] = item.name;
    });
    props.options.operators.forEach((item) => {
        map.operators[item.value] = item.name;
    });
    return map;
})

const level = computed(() => {
    return props.level === 'campaign' ? '广告系列' : '当前广告组';
});

//改变最外层且或
const changeIsOr = () => {
    emit("update:ruleList", { ...props.ruleList, connector: props.ruleList.connector === "OR" ? "AND" : "OR" });
    checkRuleAndGenerateDesc();
};

//加组
const addGroup = () => {
    if (props.ruleList.conditions[0].conditions.length == 1) {
        props.ruleList.conditions[0].conditions.push({});
    }
    props.ruleList.conditions.push({
        connector: props.ruleList.conditions[0].connector,
        conditions: [{}, {}],
    });
    emit("update:ruleList", {
        ...props.ruleList,
    });
};

const changeArrIsOr = (data) => {
    const bool = data.connector === "OR" ? "AND" : "OR";
    props.ruleList.conditions.forEach(item => {
        item.connector = bool;
    });
    checkRuleAndGenerateDesc();
};

const addRule = (data) => {
    data.push({
        field: "",
        range: "",
        operator: "",
        value: "",
    });
};

const delRule = (data, secondIndex, index) => {
    if (data.length > 1) {
        data.splice(secondIndex, 1);
    } else {
        props.ruleList.conditions.splice(index, 1);
    }
    checkRuleAndGenerateDesc();
};

const checkRuleAndGenerateDesc = () => {
    let html = '';
    let itemCount = 0;
    let groupHtml = [];

    // 如果只有一个条件，单独处理
    if (props.ruleList.conditions.length === 1 &&
        props.ruleList.conditions[0].conditions.length === 1 &&
        props.ruleList.conditions[0].conditions[0].field &&
        props.ruleList.conditions[0].conditions[0].range &&
        props.ruleList.conditions[0].conditions[0].operator &&
        props.ruleList.conditions[0].conditions[0].value !== ""
    ) {
        html = `若${level.value}在<span style="color:#2b82ff">${optionsMap.value.ranges[props.ruleList.conditions[0].conditions[0].range]}</span>，
        <span style="border-bottom: 1.5px solid #262626;">${optionsMap.value.conditions[props.ruleList.conditions[0].conditions[0].field]} ${optionsMap.value.operators[props.ruleList.conditions[0].conditions[0].operator]} ${props.ruleList.conditions[0].conditions[0].value}</span>，
        将<span style="color:#2b82ff">${typeMap[props.type]}</span>`;
        emit("desc", html);
        return;
    }

    // 如果有多个条件
    for (let i = 0; i < props.ruleList.conditions.length; i++) {
        html = '';
        itemCount = 0;
        for (let j = 0; j < props.ruleList.conditions[i].conditions.length; j++) {
            if (
                props.ruleList.conditions[i].conditions[j]?.field &&
                props.ruleList.conditions[i].conditions[j]?.range &&
                props.ruleList.conditions[i].conditions[j]?.operator &&
                props.ruleList.conditions[i].conditions[j]?.value
            ) {
                html += ` <span style="color:#2b82ff">${itemCount !== 0 ? props.ruleList.conditions[i].connector === "OR" ? "或" : "且" : ''}</span> 若${level.value}在<span style="color:#2b82ff">${optionsMap.value.ranges[props.ruleList.conditions[i].conditions[j]?.range]}</span>，
                <span style="border-bottom: 1.5px solid #262626;">${optionsMap.value.conditions[props.ruleList.conditions[i].conditions[j]?.field]} ${optionsMap.value.operators[props.ruleList.conditions[i].conditions[j]?.operator]} ${props.ruleList.conditions[i].conditions[j]?.value}</span>，
                将<span style="color:#2b82ff">${typeMap[props.type]}</span>`;
                itemCount++;
            }
        }
        groupHtml.push(html);
    }
    // 将多个组的条件拼接
    groupHtml = groupHtml.filter(item => item !== '');
    html = groupHtml.join(`<br><span style="color:#2b82ff">${props.ruleList.connector === "OR" ? "或" : "且"}</span><br>`);
    emit("desc", html);
};

watch(() => props.ruleList, () => {
    checkRuleAndGenerateDesc();
}, {
    deep: true
});

watch(() => props.level, () => {
    checkRuleAndGenerateDesc();
});

watch(() => props.type, () => {
    checkRuleAndGenerateDesc();
});
</script>

<style scoped lang="less">
ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

.rules-detail {
    display: flex;
    align-items: stretch;
    margin-bottom: 16px;

    .rule-left {
        width: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background-color: rgba(110, 172, 253);
        cursor: pointer;
        border-radius: 4px;

        &.active {
            background-color: rgba(105, 238, 173);
        }

        .link {
            width: 24px;
            height: 24px;
            border-radius: 2px;
            position: absolute;
            top: 50%;
            margin-top: -16px;

            span {
                border-radius: 2px;
                color: #fff;
                display: block;
                line-height: 20px;
                text-align: center;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }

    .rule-left-icon {
        width: 16px;
        height: 16px;
        position: absolute;
        bottom: 8px;
        border-radius: 2px;
        box-sizing: border-box;
        left: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rule-right {
        margin-left: 14px;
        flex: 1;

        .rule-left {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 14px;
                height: 1px;
                background-color: #6eacfd;
                top: 50%;
                left: -14px;
            }

            &.connectorColor {
                &::after {
                    background-color: #69eead;
                }
            }

            &.isShowLine {
                &::after {
                    height: 0px;
                }
            }
        }

        .rule-content {
            border-radius: 3px;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            .rule-title {
                display: flex;
                justify-content: space-between;
                align-items: center;

                span {
                    font-weight: 600;
                }

                div {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
            }

            .rule-content-detail {
                display: flex;
                align-items: stretch;

                ul {
                    margin: 0;
                    padding: 0;
                    background-color: #ffffff;
                    border: 1px solid #dcdfe6;

                    li {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #d2d2d2;
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            display: block;
                            width: 14px;
                            height: 1px;
                            background-color: #6eacfd;
                            top: 50%;
                            left: -14px;
                        }

                        &.isfirstOrColor {
                            &::after {
                                background-color: #69eead;
                            }
                        }

                        &.isShowFirstLine {
                            &::after {
                                height: 0px;
                            }
                        }

                        &.isShowAllLine {
                            &::after {
                                height: 1px;
                                width: 28px;
                                left: -28px;
                            }

                            &.isAllOrColor {
                                &::after {
                                    background-color: #69eead !important;
                                }
                            }

                            &.isfirstOrColor {
                                &::after {
                                    background-color: #6eacfd;
                                }
                            }
                        }

                        :deep(.el-select) {
                            width: 100%;
                        }

                        :deep(.el-input__inner) {
                            border: none !important;
                            box-shadow: none !important;
                        }

                        &:last-child {
                            border-bottom: none;
                        }

                        .space {
                            margin-right: 10px;
                            display: flex;
                            position: relative;
                            flex: 1;

                            &:last-child {
                                flex: none;
                                width: 52px !important;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                gap: 8px;

                                &::after {
                                    content: none;
                                }
                            }

                            &::after {
                                content: " ";
                                position: absolute;
                                width: 1px;
                                height: 26px;
                                background-color: #d2d2d2;
                                right: 0;
                                top: 3px;
                            }

                            &:last-child {
                                position: static;
                            }

                            .operation {
                                box-sizing: border-box;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .r {
                    margin-left: 14px;
                    flex: 1;
                }
            }
        }
    }
}

.add-group-button {
    background: #F2F6FC;
    border-radius: 4px;
    border: 1px solid #409EFF;
    color: #2b82ff;

    &:hover {
        background: #f2f6fc;
    }
}
</style>