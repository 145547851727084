<template>
    <el-dialog v-model="isDialogShow" title="选择广告系列" width="800px" top="0" :close-on-click-modal="false"
        :destroy-on-close="true" @close="$emit('close')" center custom-class="ad-rule-select-campaign-dialog"
        ref="dialogRef">
        <el-row type="flex" justify="center" align="middle" style="margin: 20px 0 16px 0;">
            <span class="card-title">广告账户</span>
            <account-select v-model="ruleForm.account_ids" placeholder="请选择关联的广告账户" size="small" style="width: 280px;"
                :popper-append-to-body="true" @change="changeAdAccount" popper-class="ad-series-account-select" />
        </el-row>
        <div class="campaign-container">
            <div class="left-container">
                <div class="header">可选广告系列</div>
                <div class="search">
                    <el-input v-model="ruleForm.keyword" placeholder="请输入广告系列ID或名称搜索" size="small"
                        @keyup.enter.native="handleSearch" clearable>
                        <template #append>
                            <el-button icon="iconfont icon-sousuo" @click="handleSearch" />
                        </template>
                    </el-input>
                </div>
                <vxe-table ref="vxeTableRef" :show-header="false" :data="tableData" height="352"
                    :row-config="{ keyField: 'id', height: 32 }" border="inner"
                    :checkbox-config="{ labelField: 'name', reserve: true }" show-overflow
                    @checkbox-change="checkboxChangeEvent" :loading="loading">
                    <vxe-column type="checkbox" title="名称"></vxe-column>
                    <vxe-column title="广告组数量" align="right" width="120">
                        <template #default="{ row }">
                            广告组（{{ row.adset_num }}）
                        </template>
                    </vxe-column>
                </vxe-table>
            </div>
            <div class="right-container">
                <div class="header">
                    <span>已选广告系列（{{ selectCount }}）</span>
                    <span class="button" @click="handleDeleteAll">清空</span>
                </div>
                <div class="list">
                    <div v-for="(item, index) in selectList" :key="index" class="item">
                        <span class="account-name"><span>{{ item.name }}</span>（{{ item.account_id }}）</span>
                        <div v-for="i in item.campaign" :key="i.id" class="campaign-tag">
                            <span class="campaign-name">{{ i.name }}</span>
                            <span>广告组（{{ i.adset_num }}）</span>
                            <el-icon @click="handleDeleteSingle(index, i.id)">
                                <i class="iconfont icon-ylclose"></i>
                            </el-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <el-button @click="isDialogShow = false" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="handleSubmit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, onMounted, provide, computed } from 'vue'
import api from '@/request/api/ad'
import AccountSelect from '@/components/base/AccountSelect.vue';
import { ElMessage } from 'element-plus';

const props = defineProps({
    id: {
        type: Number,
    },
})

const emit = defineEmits(['close', 'submit', 'add'])

const vxeTableRef = ref(null)
const dialogRef = ref(null)
const isDialogShow = ref(true)
const ruleForm = ref({
    account_ids: {}, // el-select绑定FB广告账号id
    keyword: '', // 搜索关键字
})
const loading = ref(false)
const tableData = ref([])
const selectList = ref([])
const selectCount = computed(() => {
    const count = selectList.value.reduce((prev, cur) => {
        return prev + cur.campaign?.length
    }, 0)

    return count
})
const accountOptions = ref([])
provide('accountOptions', accountOptions)

/** 获取FB用户所有广告账户 */
const getAccountList = () => {
    const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
    api.series
        .getAccountList(user.fb_user_id)
        .then((res) => {
            accountOptions.value = res.data
            ruleForm.value.account_ids = res.data[0]
            getCampaignList()
        })
}

/** 获取账户对应广告系列 */
const getCampaignList = () => {
    loading.value = true
    const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
    api.rule.getCampaignInfo({
        type: 1,
        user_id: user.fb_user_id,
        account_id: ruleForm.value.account_ids.account_id,
    }).then((res) => {
        tableData.value = res.data
        if (!props.id) return
        api.rule.getCampaignInfo({
            type: 2,
            user_id: user.fb_user_id,
            rule_id: props.id,
        }).then((res) => {
            selectList.value = res.data.map(item => {
                return {
                    account_id: item.account_id,
                    name: item.account_name,
                    campaign: item.campaign,
                }
            })
            // 如果默认第一个账户已经选择过广告系列，则勾选
            const arr = selectList.value.filter(item => item.account_id === ruleForm.value.account_ids.account_id)
            if (vxeTableRef.value && arr.length > 0) {
                vxeTableRef.value.clearCheckboxRow()
                vxeTableRef.value.setCheckboxRow(arr[0].campaign, true)
            }
        })
    }).finally(() => {
        loading.value = false
    })
}

const handleSearch = () => {
    loading.value = true
    const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
    api.rule.getCampaignInfo({
        type: 1,
        user_id: user.fb_user_id,
        account_id: ruleForm.value.account_ids.account_id,
        campaign: ruleForm.value.keyword,
    }).then((res) => {
        tableData.value = res.data
    }).finally(() => {
        loading.value = false
    })
}

/** 切换广告账户 */
const changeAdAccount = () => {
    loading.value = true
    const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
    api.rule.getCampaignInfo({
        type: 1,
        user_id: user.fb_user_id,
        account_id: ruleForm.value.account_ids.account_id,
    }).then((res) => {
        tableData.value = res.data
        // 如果切换的账户已经选择过广告系列，则勾选
        const arr = selectList.value.filter(item => item.account_id === ruleForm.value.account_ids.account_id)
        if (vxeTableRef.value && arr.length > 0) {
            vxeTableRef.value.clearCheckboxRow()
            vxeTableRef.value.setCheckboxRow(arr[0].campaign, true)
        }
    }).finally(() => {
        loading.value = false
    })
}

/** 广告系列勾选事件 */
const checkboxChangeEvent = ({ row, checked }) => {
    // 如果已经选择过该账户的广告系列，则更新, 否则新增
    if (selectList.value.filter(item => item?.account_id === ruleForm.value.account_ids.account_id).length > 0) {
        selectList.value.forEach((item, index) => {
            if (item.account_id === ruleForm.value.account_ids.account_id) {
                if (checked) {
                    selectList.value[index].campaign.push(row)
                } else {
                    selectList.value[index].campaign = selectList.value[index].campaign.filter(item => item.id !== row.id)
                    // 如果该账户勾选广告系列为空，则删除该账户
                    if (selectList.value[index].campaign.length === 0) {
                        selectList.value.splice(index, 1)
                    }
                }
            }
        })
    } else {
        const obj = { ...ruleForm.value.account_ids, campaign: vxeTableRef.value.getCheckboxRecords() }
        selectList.value.push(obj)
    }
}

/** 清空广告系列勾选 */
const handleDeleteAll = () => {
    selectList.value = []
    if (vxeTableRef.value) {
        vxeTableRef.value.clearCheckboxRow()
    }
}

/** 删除单个广告系列勾选 */
const handleDeleteSingle = (index, id) => {
    if (vxeTableRef.value) {
        vxeTableRef.value.setCheckboxRow(selectList.value[index].campaign.filter(item => item.id === id), false)
    }
    selectList.value[index].campaign = selectList.value[index].campaign.filter(item => item.id !== id)
    if (selectList.value[index].campaign.length === 0) {
        selectList.value.splice(index, 1)
    }
}

/** 提交勾选的广告系列 */
const handleSubmit = () => {
    const campaigns = selectList.value.map(item => {
        return {
            account_id: item.account_id,
            campaign_ids: item.campaign.map(i => i.id),
        }
    })
    const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))

    if (props.id) {
        api.rule.selectCampaign({
            campaigns: JSON.stringify(campaigns),
            rule_id: props.id,
            user_id: user.fb_user_id,
        }).then(res => {
            ElMessage.success(res.msg)
            emit('submit')
        })
    } else {
        emit('add', campaigns)
    }
}

onMounted(() => {
    getAccountList()
})

</script>

<style lang="less">
.ad-rule-select-campaign-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .el-dialog__title {
        font-size: 18px;
        font-family: PingFang SC-Medium;
        font-weight: 500;
        color: #262626;
    }

    &>.el-dialog__header {
        padding: 24px 0;
        border-bottom: 1px solid #f0f0f0 !important;
    }

    .el-dialog__body {
        min-height: 360px;
        padding: 0 40px;
    }

    .el-dialog__footer {
        display: flex;
        padding: 18px 40px 24px 40px !important;
        justify-content: flex-end;

        .el-button+.el-button {
            margin-left: 24px;
        }
    }

    .card-title {
        font-family: PingFang SC-Medium;
        font-weight: 500;
        font-size: 16px;
        color: #262626;
        margin-right: 8px;
    }
}
</style>

<style lang="less" scoped>
.campaign-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    .left-container {
        box-sizing: border-box;
        flex: 1;
        height: 459px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #DCDFE6;
        padding-bottom: 12px;

        .header {
            background: #F5F7FA;
            border-radius: 8px 8px 0px 0px;
            padding: 9px 16px;
            font-family: 'PingFang SC-Medium';
            font-weight: 500;
            font-size: 14px;
            color: #262626;
        }

        .search {
            padding: 8px 16px 0 16px;
            margin-bottom: 16px;

            .el-input {
                width: 100%;

                :deep(.el-input-group__append) {
                    box-sizing: border-box;
                    width: 32px;
                    height: 32px;
                    background-color: #ffffff;
                    padding: 0;
                    position: relative;

                    .el-button {
                        position: absolute;
                        right: 50%;
                        bottom: 50%;
                        transform: translate(-60%, 20%);
                        min-height: 32px;
                        padding: 0;
                    }
                }
            }
        }

        :deep(.vxe-table) {

            .vxe-cell {
                padding-left: 16px;
                color: #8c8c8c;
            }

            .vxe-checkbox--label {
                color: #262626 !important;
            }

            ::-webkit-scrollbar {
                width: 8px;
            }

            ::-webkit-scrollbar-thumb {
                background: #cccccc;
                border-radius: 8px;
            }
        }
    }

    .right-container {
        box-sizing: border-box;
        flex: 1;
        height: 459px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #DCDFE6;
        overflow: hidden;

        .header {
            display: flex;
            justify-content: space-between;
            background: #F5F7FA;
            border-radius: 8px 8px 0px 0px;
            padding: 9px 16px;
            font-family: 'PingFang SC-Medium';
            font-weight: 500;
            font-size: 14px;
            color: #262626;

            .button {
                color: #2B82FF;
                cursor: pointer;
            }
        }

        .list {
            height: 400px;
            box-sizing: border-box;
            padding: 16px;
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: #cccccc;
                border-radius: 8px;
            }

            .item {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                margin-bottom: 16px;

                .account-name {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    font-family: 'PingFang SC-Medium';
                    font-weight: 500;
                    font-size: 14px;
                    color: #8c8c8c;
                    margin-bottom: 6px;

                    span {
                        display: inline-block;
                        max-width: 160px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }

                .campaign-tag {
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-family: 'PingFang SC-Medium';
                    font-weight: 500;
                    font-size: 12px;
                    color: #262626;
                    margin-bottom: 6px;
                    width: 100%;
                    padding: 0 8px;
                    height: 32px;
                    background: #EBECEF;
                    border-radius: 2px;

                    .campaign-name {
                        width: 190px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    span {
                        margin-right: 8px;
                    }

                    .iconfont {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>
