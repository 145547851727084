<template>
    <el-row class="rule-record">
        <el-col>
            <el-card :body-style="{ 'padding': '20px 20px 80px 20px', 'min-height': cardHeight + 'px' }">
                <template #header>
                    <el-row justify="space-between">
                        <span class="SubHead">规则日志</span>
                        <el-link type="primary" :underline="false" @click="$router.push('/custom_rule')">返回</el-link>
                    </el-row>
                </template>
                <search-form-content ref="searchFormContentRef" :form-items="items" :fields="fields"
                    :default-page-size="10" :fetch-data="fetchData" :empty-show="true">
                    <template #empty>
                        <el-empty :image-size="300">
                            <template #description>
                                <span class="rule-record-empty-desc">暂无日志</span>
                            </template>
                            <template #image>
                                <img src="@/assets/empty.gif" />
                            </template>
                        </el-empty>
                    </template>
                </search-form-content>
            </el-card>
        </el-col>
    </el-row>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import SearchFormContent from '@/components/base/SearchFormContent'
import api from '@/request/api/ad'
import filters from '@/base/filters/base'

const searchFormContentRef = ref(null)
const cardHeight = ref(window.innerHeight - 240)

const items = {
    execu_time: {
        type: 'daterange',
        label: '执行时间',
        value: filters.getDefaultDuration(),
        disableDate: (time) => {
            return time.getTime() < 1640966400 * 1000 || time.getTime() > Date.now() - 3600 * 1000 * 24
        },
    },
    account_name: { label: '广告账户', placeholder: '请输入广告账户名称' },
    rule_id: {
        type: 'selector',
        label: '执行规则',
        options: [],
    },
    campaign_name: { label: '广告系列', placeholder: '请输入广告系列名称' },
}

const fields = [
    { id: 'account_name', label: '广告账户名称', fixed: 'left' },
    { id: 'account_id', label: '广告账户id' },
    { id: 'campaign_name', label: '广告系列名称' },
    { id: 'rule_name', label: '执行规则' },
    { id: 'msg', label: '执行内容' },
    { id: 'status', label: '执行状态' },
    { id: 'runned_at', label: '执行时间' }
]

const fetchData = async (params) => {
    const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
    let res = await api.rule.getRuleRecord({ ...params, user_id: user.fb_user_id })

    console.log(res)
    return {
        page: {
            total: res && res.total ? res.total : 0,
            page: params.page ? params.page : 1,
            pagesize: params.pagesize ? params.pagesize : 10,
        },
        data: res && res.data && res.data.length > 0 ? res.data : [],
    }
}

onMounted(() => {
    api.rule.getRuleList().then(res => {
        items.rule_id.options = res.rule
    })
})
</script>

<style lang="less" scoped>
.rule-record {
    :deep(.search-form) {
        width: 100%;
    }

    :deep(.rule-record-empty-desc) {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        color: #595959;

        .el-button {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
        }
    }

    :deep(.search-form) {
        .el-form-item {
            margin-right: 20px;
        }
    }

    :deep(.el-table .el-table__cell.is-center:not(:nth-child(5))) {
        text-align: left;
    }

    :deep(.el-table__row .el-table__cell:first-child) {
        padding-left: 40px;
    }

    :deep(tr .cell) {
        padding-left: 0;
    }

    :deep(thead tr th:first-child .cell) {
        padding-left: 40px;
    }

    :deep(.el-row.is-justify-end) {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}
</style>