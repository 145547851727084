<template>
    <el-card style="margin-bottom: 16px;" shadow="never">
        <el-row justify="space-between">
            <span class="SubHead">广告模板批量创建日志</span>
            <el-link type="primary" :underline="false" @click="$router.push('/batch_ad_template')"
                style="color: #2b82ff;">返回</el-link>
        </el-row>
    </el-card>
    <el-row class="batch-record">
        <el-col>
            <el-card :body-style="{ 'padding': '32px 20px 80px 20px', 'min-height': cardHeight + 'px' }" shadow="never">
                <template #header>
                    <div>
                        <div v-if="accountInfo && accountInfo.fb_user_id" class="login-user">
                            <img :src="accountInfo.fb_avatar" alt="avatar" />
                            <span>{{ accountInfo.fb_name }}</span>
                            <el-button size="small" plain class="login-button"
                                @click="isDialogShow = true;">切换Facebook授权</el-button>
                        </div>
                        <el-button size="small" plain class="login-button" @click="isDialogShow = true;"
                            v-else>授权Facebook用户</el-button>
                    </div>
                </template>
                <div>
                    <search-form-content ref="searchFormContentRef" :form-items="items" :fields="fields"
                        :default-page-size="10" :fetch-data="fetchData" :empty-show="true"
                        v-if="accountInfo && accountInfo.fb_user_id">
                        <template #empty>
                            <el-empty :image-size="300">
                                <template #description>
                                    <span class="batch-record-empty-desc">暂无日志</span>
                                </template>
                                <template #image>
                                    <img src="@/assets/empty.gif" />
                                </template>
                            </el-empty>
                        </template>
                        <template #operations>
                            <el-table-column label="创建结果">
                                <template #default="{ row }">
                                    <div :class="['create-result', hasFail(row) ? 'fail-status' : 'success-status']">
                                        <span>广告系列：{{ row.campaign }}</span>
                                        <span>广告组：{{ row.adset }}</span>
                                        <span>广告：{{ row.ads }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="100">
                                <template #default="{ row }">
                                    <el-button type="text" class="operate-button" size="small"
                                        @click="handleDetail(row)">详情</el-button>
                                </template>
                            </el-table-column>
                        </template>
                    </search-form-content>
                    <el-empty :image-size="300" class="unauth" v-else>
                        <template #description>
                            <span class="ad-series-empty-desc">
                                未授权，请先<el-button type="text" @click="isDialogShow = true">授权Facebook用户</el-button>
                            </span>
                        </template>
                        <template #image>
                            <img src="@/assets/empty.gif" />
                        </template>
                    </el-empty>
                </div>
            </el-card>
        </el-col>
    </el-row>

    <!-- FB授权弹窗 -->
    <RecordLoginDialog v-if="isDialogShow" @close="isDialogShow = false;" @submit="handleFBAuth" />

    <!-- 详情弹窗 -->
    <el-dialog title="创建结果详情" v-model="isDetailDialogShow" width="1068px" center
        custom-class="batch-record-detail-dialog">
        <el-table :data="detailTableData" height="506" style="width: 100%"
            :header-cell-style="{ background: 'var(--el-table-row-hover-background-color)', padding: '0' }">
            <el-table-column prop="account_id" label="广告账户ID" align="left" />
            <el-table-column prop="campaign_name" label="广告系列" align="left" show-overflow-tooltip />
            <el-table-column prop="adsets_name" label="广告组" align="left" show-overflow-tooltip />
            <el-table-column prop="adv_name" label="广告名称" align="left" show-overflow-tooltip />
            <el-table-column prop="status" label="创建结果" align="left">
                <template #default="{ row }">
                    <span :class="['create-result', hasDetailFail(row) ? 'fail-status' : 'success-status']">{{
                        row.status.includes('成功') ? '成功' : '失败' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="msg" label="备注" align="left" show-overflow-tooltip />
            <el-table-column prop="time" label="创建时间" align="left" width="150" />
        </el-table>
        <template #footer>
            <div class="button" @click="isDetailDialogShow = false">确定</div>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import SearchFormContent from '@/components/base/SearchFormContent'
import RecordLoginDialog from './RecordLoginDialog.vue'
import api from '@/request/api/ad'
import filters from '@/base/filters/base'

const searchFormContentRef = ref(null)
const cardHeight = ref(window.innerHeight - 332)
const selectedItem = ref(null)
const accountInfo = ref(JSON.parse(localStorage.getItem('CurrentFBAuthUser')))
const isDialogShow = ref(false)
const isDetailDialogShow = ref(false)
const detailTableData = ref([])

const items = {
    create_time: {
        type: 'daterange',
        label: '创建时间',
        value: filters.getDefaultDuration(),
    },
    keyword: { label: '广告账户', placeholder: '请输入广告ID或账户名称' }
}

const fields = [
    { id: 'time', label: '创建时间', fixed: 'left', width: '50' },
]

const fetchData = async (params) => {
    const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
    let res = await api.template.getBatchLog({ ...params, user_id: user.fb_user_id })

    console.log(res)
    return {
        page: {
            total: res && res.total ? res.total : 0,
            page: params.page ? params.page : 1,
            pagesize: params.pagesize ? params.pagesize : 10,
        },
        data: res && res.data && res.data.length > 0 ? res.data : [],
    }
}

/** 更改FB授权状态 */
const handleFBAuth = (user) => {
    accountInfo.value = user
    isDialogShow.value = false
    if (searchFormContentRef.value) {
        searchFormContentRef.value.refreshData()
    }
}

const hasFail = (row) => {
    row = row.campaign + row.adset + row.ads;
    // 使用正则表达式匹配所有“创建失败”后的数字
    const failureMatches = row.match(/创建失败(\d+)/g);

    // 如果没有匹配到“创建失败”，则返回false
    if (!failureMatches) {
        return false;
    }

    // 检查每个匹配的失败次数
    return failureMatches.some(match => {
        const failureCount = parseInt(match.match(/\d+/)[0], 10);
        return failureCount > 0;
    });
}

const hasDetailFail = (row) => {
    return row.status.includes('失败');
}

const handleDetail = (row) => {
    selectedItem.value = row;
    isDetailDialogShow.value = true
    api.template.getBatchLogDetail({ id: selectedItem.value.id }).then(res => {
        detailTableData.value = res.data;
    })
}
</script>

<style lang="less">
.batch-record-detail-dialog.el-dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0;
    transform: translate(-50%, -50%);

    .el-dialog__header {
        padding: 16px 40px;
        border-bottom: 1px solid #F0F0F0 !important;

        .el-dialog__title {
            font-family: PingFang SC-Medium;
            color: #262626;
            font-weight: 500;
        }
    }

    .el-dialog__body {
        padding: 12px 20px 0 20px;
    }

    .el-dialog__footer {
        display: flex;
        justify-content: center;
        padding: 32px 0 40px 0 !important;
    }

    .create-result {
        width: 42px;
        display: flex;
        justify-content: flex-end;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #595959;
        }
    }

    .create-result.fail-status::before {
        background-color: #F56C6C;
    }

    .create-result.success-status::before {
        background-color: #00F1A2;
    }

    .button {
        width: 92px;
        height: 36px;
        background: #409EFF;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
    }
}
</style>

<style lang="less" scoped>
.batch-record {
    position: relative;

    :deep(.el-card) {
        background-color: transparent;
        border: none;

        .el-card__header {
            min-width: 290px;
            width: fit-content;
            border-bottom: none;
            background-color: #ffffff;
        }

        .el-card__body {
            background-color: #ffffff;
        }
    }

    :deep(.login-button.el-button) {
        border: 1px solid #2b82ff;
        font-size: 14px;
        font-family: PingFang SC-Medium;
        font-weight: 500;
        color: #2b82ff;
    }

    .login-user {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 16px;

        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }

        span {
            line-height: 16px;
            vertical-align: middle;
        }
    }

    :deep(.batch-record-empty-desc) {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        color: #595959;

        .el-button {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
        }
    }

    :deep(.search-form) {
        width: 100%;

        .el-form-item {
            margin-right: 20px;
        }

        .search-form-buttons {
            float: right;
        }
    }

    :deep(.el-table .el-table__cell.is-center:not(:nth-child(5))) {
        text-align: left;
    }

    :deep(.el-table__row .el-table__cell:first-child) {
        padding-left: 40px;
    }

    :deep(tr .cell) {
        padding-left: 0;
    }

    :deep(thead tr th:first-child .cell) {
        padding-left: 40px;
    }

    :deep(.el-row.is-justify-end) {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }

    .create-result {
        width: 240px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-weight: 400;
        font-size: 12px;
        color: #595959;
        line-height: 20px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #595959;
        }
    }

    .create-result.fail-status::before {
        background-color: #F56C6C;
    }

    .create-result.success-status::before {
        background-color: #00F1A2;
    }

    .operate-button {
        color: #2B82FF;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
    }

    .unauth {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .ad-series-empty-desc {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #8c8c8c;

            .el-button {
                color: #2b82ff;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
            }
        }
    }
}
</style>