<template>
    <el-row class="custom-rule">
        <el-col>
            <el-card :body-style="{ 'padding': '20px 20px 80px 20px', 'min-height': cardHeight + 'px' }"
                v-loading="loading">
                <template #header>
                    <el-row justify="space-between">
                        <div>
                            <span class="SubHead" v-if="!operateStatus">自动规则</span>
                            <span class="SubHead" v-else>{{ operateStatus === 'add' ? '新建规则' : '编辑规则' }}</span>
                        </div>
                        <div>
                            <div v-if="!operateStatus">
                                <div v-if="accountInfo && accountInfo.fb_user_id" class="login-user">
                                    <img :src="accountInfo.fb_avatar" alt="avatar" />
                                    <span>{{ accountInfo.fb_name }}</span>
                                    <el-button size="small" plain class="login-button"
                                        @click="isDialogShow = true;">切换Facebook授权</el-button>
                                </div>
                                <el-button size="small" plain class="login-button" @click="isDialogShow = true;"
                                    v-else>授权Facebook用户</el-button>
                            </div>
                            <el-link type="primary" :underline="false" @click="goBack" v-else>返回</el-link>
                        </div>
                    </el-row>
                </template>
                <div v-show="!operateStatus">
                    <search-form-content ref="searchFormContentRef" type="list" :form-items="items"
                        :fetch-data="fetchData" :empty-show="true" v-if="accountInfo && accountInfo.fb_user_id"
                        class="custom-rule-list">
                        <template #empty>
                            <el-empty :image-size="300">
                                <template #description>
                                    <span class="custom-rule-empty-desc">暂无日志</span>
                                </template>
                                <template #image>
                                    <img src="@/assets/empty.gif" />
                                </template>
                            </el-empty>
                        </template>
                        <template #tool>
                            <el-button size="small" type="primary" icon="el-icon-plus" style="margin-right: 8px;"
                                @click="addRule">新建规则</el-button>
                            <el-button size="small" type="primary" @click="$router.push('/rule_record')"
                                icon="el-icon-tickets">规则日志</el-button>
                        </template>
                        <template #content="slotProps">
                            <el-table :data="slotProps.data"
                                :header-cell-style="{ background: 'var(--el-table-row-hover-background-color)' }"
                                border>
                                <el-table-column label="规则名称" min-width="100">
                                    <template #default="{ row }">
                                        <div class="rule-row">
                                            <el-tag size="small" :type="row.ads_level === '广告系列' ? '' : 'success'"
                                                style="margin-right: 8px;">
                                                {{ row.ads_level }}
                                            </el-tag>
                                            <span class="rule-name">{{ row.name }}</span>
                                            <el-row type="flex" align="middle" style="width: 100px;">
                                                <vxe-switch v-model="row.status" :open-value="1" :close-value="0"
                                                    open-label="启用" close-label="禁用"
                                                    @change="switchChange(row.id, row.status)"
                                                    v-show="row.crm_id !== 0" />
                                            </el-row>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="应用范围" min-width="50">
                                    <template #default="{ row }">
                                        <div>广告系列<span style="color: #2b82ff;cursor: pointer;"
                                                @click="selectedRule = row; isCampaignDialogShow = true">
                                                （{{ row.campaign_num }}个）</span></div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="create_time" label="创建时间" sortable min-width="50" />
                                <el-table-column label="操作" width="200px">
                                    <template #default="{ row }">
                                        <el-row type="flex" align="middle" class="tool-container">
                                            <div class="tool-box" @click="editRule(row)" v-show="row.name !== '成本控制策略'">
                                                <el-icon class="tool-icon">
                                                    <i class="iconfont icon-bianji"></i>
                                                </el-icon>
                                                <div class="tool-button">编辑</div>
                                            </div>
                                            <div class="tool-box" @click="selectedRule = row; isCopyDialogShow = true"
                                                v-show="row.crm_id !== 0">
                                                <el-icon class="tool-icon">
                                                    <i class="iconfont icon-fuzhi"></i>
                                                </el-icon>
                                                <div class="tool-button">复制</div>
                                            </div>
                                            <div class="tool-box" @click="selectedRule = row; isDeleteDialogShow = true"
                                                v-show="row.crm_id !== 0">
                                                <el-icon class="tool-icon">
                                                    <i class="iconfont icon-shanchu"></i>
                                                </el-icon>
                                                <div class="tool-button">删除</div>
                                            </div>
                                        </el-row>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </search-form-content>
                    <el-empty :image-size="300" class="unauth"
                        v-show="!loading && !accountInfo && !accountInfo?.fb_user_id">
                        <template #description>
                            <span class="ad-series-empty-desc">
                                未授权，请先<el-button type="text" @click="isDialogShow = true">授权Facebook用户</el-button>
                            </span>
                        </template>
                        <template #image>
                            <img src="@/assets/empty.gif" />
                        </template>
                    </el-empty>
                </div>
                <!-- 新建规则 -->
                <div v-if="operateStatus" class="add-rule-container">
                    <el-form :rules="rules" ref="ruleFormRef" :model="ruleForm">
                        <div class="base-container">
                            <div class="title">基础设置</div>
                            <el-form-item label="规则名称：" prop="name" required>
                                <el-input v-model="ruleForm.name" placeholder="请输入规则名称" size="small"
                                    style="width: 508px;" clearable :validate-event="false" />
                            </el-form-item>
                            <el-form-item label="生效时间：" prop="time_type" required style="margin-bottom: 12px;">
                                <el-row type="flex" align="middle">
                                    <el-radio-group v-model="ruleForm.time_type">
                                        <el-radio :label="1">长期</el-radio>
                                        <el-radio :label="2">自定义</el-radio>
                                    </el-radio-group>
                                    <el-row type="flex" align="middle" style="margin-left: 16px;"
                                        v-show="ruleForm.time_type === 2">
                                        <el-select v-model="ruleForm.start_hour" placeholder="请选择" size="small">
                                            <el-option v-for="item in timeOptions" :key="item.id" :label="item.name"
                                                :value="item.id" />
                                        </el-select>
                                        <span style="margin: 0 8px;">至</span>
                                        <el-select v-model="ruleForm.end_hour" placeholder="请选择" size="small">
                                            <el-option v-for="item in timeOptions" :key="item.id" :label="item.name"
                                                :value="item.id" />
                                        </el-select>
                                    </el-row>
                                </el-row>
                            </el-form-item>
                            <el-form-item label="检查频次：">
                                <span style="color:#595959">每半个小时</span>
                            </el-form-item>
                        </div>
                        <div class="rule-container">
                            <div class="title">规则设置</div>
                            <div class="condition-container">
                                <span class="title">规则条件</span>
                                <div class="item" style="padding: 20px 16px 24px 16px;">
                                    <Rule ref="ruleRef" v-model:ruleList="ruleForm.rule" :options="ruleOptions"
                                        :level="ruleForm.ads_level" :type="ruleForm.type"
                                        @desc="e => ruleForm.description = e" />
                                </div>
                                <div class="item">
                                    <el-form-item label="规则应用层级：" prop="ads_level" required class="rule-apply-level">
                                        <el-radio-group v-model="ruleForm.ads_level"
                                            :disabled="operateStatus === 'edit'">
                                            <el-radio label="campaign">广告系列</el-radio>
                                            <el-radio label="adset">广告系列中的广告组</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </div>
                                <div class="item">
                                    <el-form-item label="规则操作：" prop="type" required class="rule-operate">
                                        <el-select v-model="ruleForm.type" placeholder="请选择" size="small"
                                            :validate-event="false">
                                            <el-option v-for="item in typeOptions" :key="item.id" :label="item.name"
                                                :value="item.id" />
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="item">
                                    <div class="rule-desc-title" @click="isShowDesc = !isShowDesc">
                                        <span>规则描述</span>
                                        <el-icon>
                                            <i class="iconfont el-icon-arrow-down" v-show="isShowDesc"></i>
                                            <i class="iconfont el-icon-arrow-right" v-show="!isShowDesc"></i>
                                        </el-icon>
                                    </div>
                                    <div v-html="ruleForm.description" class="rule-desc-content" v-if="isShowDesc">
                                    </div>
                                </div>
                            </div>
                            <el-form-item label="应用范围：" v-show="operateStatus === 'add'">
                                <el-row type="flex" align="middle">
                                    <el-button size="small" plain class="login-button"
                                        @click="isCampaignDialogShow = true;">选择广告系列</el-button>
                                    <span class="select-count" v-show="selectCount !== 0">已选择{{
                                        selectCount
                                    }}个广告系列</span>
                                </el-row>
                            </el-form-item>
                            <el-form-item label="消息通知：">
                                <el-row type="flex" align="middle">
                                    <vxe-checkbox v-model="ruleForm.is_notice" size="medium"
                                        style="margin-right: 8px;">发送通知到：</vxe-checkbox>
                                    <div class="email-list">
                                        <el-row type="flex" v-if="!ruleForm.editEmail">
                                            <span v-for="(item, index) in ruleForm.email" :key="index" class="email">{{
                                                item }}</span>
                                            <span class="button" @click="ruleForm.editEmail = true">编辑</span>
                                        </el-row>
                                        <el-row type="flex" align="middle" v-else>
                                            <div v-for="(item, index) in ruleForm.email" :key="index" class="email-tag">
                                                <span>{{ item }}</span>
                                                <el-icon @click="handleDeleteEmail(index)">
                                                    <i class="iconfont icon-ylclose"></i>
                                                </el-icon>
                                            </div>
                                            <el-form-item prop="emailInput" style="margin-bottom: 0;">
                                                <el-input v-model="ruleForm.emailInput" placeholder="回车确定输入邮箱"
                                                    size="small" style="width:160px;" clearable :validate-event="false"
                                                    @keyup.enter.native="addEmail" />
                                            </el-form-item>
                                        </el-row>
                                    </div>
                                </el-row>
                            </el-form-item>
                        </div>
                        <div class="footer">
                            <el-button @click="goBack" size="small">取消</el-button>
                            <el-button type="primary" size="small" @click="handleSubmit"
                                v-show="operateStatus === 'add'">创建规则</el-button>
                            <el-button @click="isSaveNewDialogShow = true" size="small"
                                v-show="operateStatus === 'edit'">另存为新的规则</el-button>
                            <el-button type="primary" size="small" @click="handleSubmit"
                                v-show="operateStatus === 'edit'">确定</el-button>
                        </div>
                    </el-form>
                </div>
            </el-card>
        </el-col>
    </el-row>

    <!-- FB授权弹窗 -->
    <RecordLoginDialog v-if="isDialogShow" @close="isDialogShow = false;" @submit="handleFBAuth" />

    <!-- 选择广告系列弹窗 -->
    <SelectCampaignDialog v-if="isCampaignDialogShow" @close="isCampaignDialogShow = false; selectedRule = null"
        :id="selectedRule ? selectedRule.id : null"
        @submit="isCampaignDialogShow = false; searchFormContentRef.refreshData()"
        @add="e => { ruleForm.campaigns = e; isCampaignDialogShow = false; }" />

    <!-- 删除规则弹窗 -->
    <el-dialog v-model="isDeleteDialogShow" width="450px" top="30vh" :close-on-click-modal="false"
        custom-class="custom-rule-delete-dialog">
        <div class="delete">
            <img src="@/assets/images/business/warning.png" alt="">
            <div class="delete-title">是否确认删除此规则？规则删除后将无法恢复，所有应用此规则的广告系列后续投放将不再沿用此规则。</div>
            <div>
                <el-button @click="isDeleteDialogShow = false;" size="small">取消</el-button>
                <el-button type="danger" size="small" @click="handleDelete">删除</el-button>
            </div>
        </div>
    </el-dialog>

    <!-- 复制规则弹窗 -->
    <el-dialog v-model="isCopyDialogShow" width="564px" top="30vh" title="复制规则" :close-on-click-modal="false"
        custom-class="custom-rule-copy-dialog">
        <div class="copy">
            <span>请选择复制的新规则应用层级：</span>
            <el-radio-group v-model="copy_ads_level">
                <el-radio label="campaign">广告系列</el-radio>
                <el-radio label="adset">广告系列中的广告组</el-radio>
            </el-radio-group>
        </div>
        <template #footer>
            <el-button @click="isCopyDialogShow = false;" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="handleCopy">确定</el-button>
        </template>
    </el-dialog>

    <!-- 编辑保存弹窗 -->
    <el-dialog v-model="isSaveDialogShow" width="564px" top="30vh" :close-on-click-modal="false"
        custom-class="custom-rule-edit-dialog">
        <template #title>
            <i class="iconfont icon-jingshi"></i>
            <span class="title">是否确定保存修改？</span>
        </template>
        <div class="save">规则修改后，应用此规则的广告系列将从规则下一次生效时应用修改后的新规则。</div>
        <template #footer>
            <el-button @click="isSaveDialogShow = false;" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="handleEditSubmit">确定</el-button>
        </template>
    </el-dialog>

    <!-- 另存为新规则弹窗 -->
    <el-dialog v-model="isSaveNewDialogShow" width="564px" top="30vh" title="请输入新的规则名称" :close-on-click-modal="false"
        custom-class="custom-rule-copy-dialog" :destroy-on-close="true">
        <el-form :rules="rules" ref="nameFormRef" :model="ruleForm" style="margin-top: 32px;">
            <el-form-item label="规则名称：" prop="name" required>
                <el-input v-model="ruleForm.name" placeholder="请输入规则名称" size="small" clearable :validate-event="false"
                    style="width: 80%;" />
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="isSaveNewDialogShow = false;" size="small">取消</el-button>
            <el-button type="primary" size="small" @click="handleSaveNewSubmit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, computed } from 'vue'
import SearchFormContent from '@/components/base/SearchFormContent'
import RecordLoginDialog from '@/views/ads/batchAdTemplate/RecordLoginDialog'
import SelectCampaignDialog from '@/views/ads/adRule/SelectCampaignDialog'
import Rule from '@/views/ads/adRule/Rule'
import api from '@/request/api/ad'
import { ElMessage } from 'element-plus'

const { proxy } = getCurrentInstance()
const searchFormContentRef = ref(null)
const ruleRef = ref(null)
const cardHeight = ref(window.innerHeight - 240)
const operateStatus = ref(null)
const selectedRule = ref(null)
const accountInfo = ref(null)
const isDialogShow = ref(false)
const isDeleteDialogShow = ref(false)
const isCopyDialogShow = ref(false)
const isSaveDialogShow = ref(false)
const isSaveNewDialogShow = ref(false)
const isCampaignDialogShow = ref(false)
const loading = ref(false)
const copy_ads_level = ref('campaign')
const ruleFormRef = ref(null)
const nameFormRef = ref(null)
const isShowDesc = ref(true)
const ruleForm = ref({
    name: '',
    time_type: 1,
    start_hour: 0,
    end_hour: 0,
    rule: {
        connector: 'AND',
        conditions: [
            {
                connector: 'AND',
                conditions: [{
                    field: '',
                    range: '',
                    operator: '',
                    value: ''
                }]
            }
        ]
    },
    type: 1,
    description: '',
    ads_level: 'campaign',
    campaigns: [],
    is_notice: true,
    editEmail: false,
    emailInput: '',
    email: []
})

const rules = {
    name: [{ required: true, message: '请输入规则名称' }],
    type: [{ required: true, message: '请选择规则操作' }],
    emailInput: [{
        pattern: /(^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$)/,
        message: '请输入正确格式的邮箱号码',
    }]
}

const items = {
    campaign: { label: '广告系列', placeholder: '请输入广告系列ID或名称' },
    rule_name: { label: '规则名称', placeholder: '请输入规则名称' },
    status: {
        type: 'selector',
        label: '规则状态',
        options: [
            { id: 1, name: '启用' },
            { id: 0, name: '禁用' }
        ],
        placeholder: '请选择'
    }
}

const ruleOptions = ref([])
const timeOptions = Array.from({ length: 24 }, (_, index) => ({ id: index, name: `${index}:00` }));
const typeOptions = ref([
    { id: 1, name: '关闭' },
    { id: 2, name: '开启' },
])

const selectCount = computed(() => {
    const count = ruleForm.value.campaigns.reduce((prev, cur) => {
        return prev + cur.campaign_ids?.length
    }, 0)

    return count
})

const fetchData = async (params) => {
    let res = await api.rule.getRuleList({ ...params, user_id: accountInfo.value.fb_user_id })

    return {
        page: {
            total: res && res.total ? res.total : 0,
            page: params.page ? params.page : 1,
            pagesize: params.pagesize ? params.pagesize : 10,
        },
        data: res && res.rule && res.rule.length > 0 ? res.rule : [],
    }
}

/** 更改FB授权状态 */
const handleFBAuth = (user) => {
    accountInfo.value = user
    isDialogShow.value = false
    if (searchFormContentRef.value) {
        searchFormContentRef.value.refreshData()
    }
}

/** 检查用户授权 */
const checkAuth = () => {
    loading.value = true
    return new Promise((resolve, reject) => {
        if (localStorage.getItem('CurrentFBAuthUser')) {
            const user = JSON.parse(localStorage.getItem('CurrentFBAuthUser'))
            api.template.getFBAuthExpire(user.fb_user_id).then((res) => {
                if (res.code === 200) {
                    accountInfo.value = user
                }
                resolve()
            }).catch((error) => {
                resolve()
            }).finally(() => {
                loading.value = false
            })
        } else {
            loading.value = false
            resolve()
        }
    })
}

const addRule = () => {
    ruleForm.value = {
        name: '',
        time_type: 1,
        start_hour: 0,
        end_hour: 0,
        rule: {
            connector: 'AND',
            conditions: [
                {
                    connector: 'AND',
                    conditions: [{
                        field: '',
                        range: '',
                        operator: '',
                        value: ''
                    }]
                }
            ]
        },
        type: 1,
        description: '',
        ads_level: 'campaign',
        campaigns: [],
        is_notice: true,
        editEmail: false,
        emailInput: '',
        email: []
    }
    ruleForm.value.email.push(proxy.$store.getters.userInfo.email)
    operateStatus.value = 'add';
    selectedRule.value = null
    api.rule.getRuleConfig().then(res => {
        ruleOptions.value = res.data
    })
}

const editRule = (row) => {
    selectedRule.value = row;
    operateStatus.value = 'edit'
    api.rule.getRuleConfig().then(res => {
        ruleOptions.value = res.data
        api.rule.editCustomRule({
            rule_id: row.id,
            edit_type: 0
        }).then(res => {
            ruleForm.value.ads_level = res.data.ads_level
            ruleForm.value.email = res.data.email.split(',')
            ruleForm.value.name = res.data.name
            ruleForm.value.rule = JSON.parse(res.data.evaluation)
            ruleForm.value.time_type = res.data.time_type
            ruleForm.value.start_hour = res.data.start_hour
            ruleForm.value.end_hour = res.data.end_hour
            ruleForm.value.type = res.data.type
        })
    })
}

const switchChange = (id, status) => {
    api.rule.updateRuleStatus({
        rule_id: id,
        status: status
    }).then(res => {
        if (res.code === 200) {
            ElMessage.success(res.msg)
        }
    }).catch(err => {
        if (searchFormContentRef.value) {
            searchFormContentRef.value.refreshData()
        }
    })
}

const handleDelete = () => {
    api.rule.deleteRule({ rule_id: selectedRule.value.id }).then(res => {
        if (res.code === 200) {
            ElMessage.success(res.msg)
            isDeleteDialogShow.value = false
            if (searchFormContentRef.value) {
                searchFormContentRef.value.refreshData()
            }
        }
    }).catch(err => {
        if (searchFormContentRef.value) {
            searchFormContentRef.value.refreshData()
        }
    })
}

const handleCopy = () => {
    api.rule.copyRule({
        rule_id: selectedRule.value.id,
        ads_level: copy_ads_level.value
    }).then(res => {
        if (res.code === 200) {
            ElMessage.success(res.msg)
            isCopyDialogShow.value = false
            if (searchFormContentRef.value) {
                searchFormContentRef.value.refreshData()
            }
        }
    }).catch(err => {
        if (searchFormContentRef.value) {
            searchFormContentRef.value.refreshData()
        }
    })
}

const handleDeleteEmail = (index) => {
    if (ruleForm.value.email.length === 1) {
        ElMessage.warning('至少保留一个邮箱')
        return
    }
    ruleForm.value.email.splice(index, 1)
}

const addEmail = () => {
    ruleFormRef.value.validateField('emailInput', (error) => {
        if (error) return
        if (ruleForm.value.emailInput) ruleForm.value.email.push(ruleForm.value.emailInput)
        ruleForm.value.emailInput = ''
        ruleForm.value.editEmail = false
    })
}

const handleEditSubmit = () => {
    api.rule.editCustomRule({
        rule_id: selectedRule.value.id,
        name: ruleForm.value.name,
        type: ruleForm.value.type,
        evaluation: JSON.stringify(ruleForm.value.rule),
        description: ruleForm.value.description,
        time_type: ruleForm.value.time_type,
        start_hour: ruleForm.value.time_type === 2 ? ruleForm.value.start_hour : undefined,
        end_hour: ruleForm.value.time_type === 2 ? ruleForm.value.end_hour : undefined,
        email: ruleForm.value.is_notice ? ruleForm.value.email.join(',') : undefined,
        edit_type: 1
    }).then(res => {
        isSaveDialogShow.value = false
        ElMessage.success({
            message: res.msg,
            duration: 2000,
            onClose: () => {
                if (proxy.$route.query.path) {
                    proxy.$router.push({ path: proxy.$route.query.path, query: { c_id: proxy.$route.query.c_id } })
                    return
                }
                if (searchFormContentRef.value) {
                    operateStatus.value = null
                    searchFormContentRef.value.refreshData()
                }
            }
        })
    }).catch(err => {
        if (searchFormContentRef.value) {
            searchFormContentRef.value.refreshData()
        }
    })
}

const handleSaveNewSubmit = () => {
    nameFormRef.value.validate((valid) => {
        if (valid) {
            api.rule.addCustomRule({
                user_id: accountInfo.value.fb_user_id,
                name: ruleForm.value.name,
                type: ruleForm.value.type,
                evaluation: JSON.stringify(ruleForm.value.rule),
                description: ruleForm.value.description,
                time_type: ruleForm.value.time_type,
                start_hour: ruleForm.value.time_type === 2 ? ruleForm.value.start_hour : undefined,
                end_hour: ruleForm.value.time_type === 2 ? ruleForm.value.end_hour : undefined,
                campaigns: JSON.stringify(ruleForm.value.campaigns),
                ads_level: ruleForm.value.ads_level,
                email: ruleForm.value.is_notice ? ruleForm.value.email.join(',') : undefined
            }).then(res => {
                isSaveNewDialogShow.value = false
                ElMessage.success({
                    message: res.msg,
                    duration: 2000,
                    onClose: () => {
                        if (proxy.$route.query.path) {
                            proxy.$router.push({ path: proxy.$route.query.path, query: { c_id: proxy.$route.query.c_id } })
                            return
                        }
                        if (searchFormContentRef.value) {
                            operateStatus.value = null
                            searchFormContentRef.value.refreshData()
                        }
                    }
                })
            })
        }
    })
}

function isNumber(str) {
    // 正则表达式匹配非负数，包括整数和小数
    const regex = /^[+]?[0-9]*\.?[0-9]+$/;
    return regex.test(str) && parseFloat(str) >= 0;
}

const handleSubmit = () => {
    ruleFormRef.value.validate((valid) => {
        if (valid) {
            for (let i = 0; i < ruleForm.value.rule.conditions.length; i++) {
                for (let j = 0; j < ruleForm.value.rule.conditions[i].conditions.length; j++) {
                    if (
                        !ruleForm.value.rule.conditions[i].conditions[j]?.field ||
                        !ruleForm.value.rule.conditions[i].conditions[j]?.range ||
                        !ruleForm.value.rule.conditions[i].conditions[j]?.operator ||
                        ruleForm.value.rule.conditions[i].conditions[j]?.value === ""
                    ) {
                        ElMessage.warning('请完善规则条件')
                        return
                    }
                    if (!isNumber(ruleForm.value.rule.conditions[i].conditions[j]?.value)) {
                        ElMessage.warning('规则条件的数值必须为大于等于0的数字或小数')
                        return
                    }
                }
            }

            if (operateStatus.value === 'add') {
                api.rule.addCustomRule({
                    user_id: accountInfo.value.fb_user_id,
                    name: ruleForm.value.name,
                    type: ruleForm.value.type,
                    evaluation: JSON.stringify(ruleForm.value.rule),
                    description: ruleForm.value.description,
                    time_type: ruleForm.value.time_type,
                    start_hour: ruleForm.value.time_type === 2 ? ruleForm.value.start_hour : undefined,
                    end_hour: ruleForm.value.time_type === 2 ? ruleForm.value.end_hour : undefined,
                    campaigns: JSON.stringify(ruleForm.value.campaigns),
                    ads_level: ruleForm.value.ads_level,
                    email: ruleForm.value.is_notice ? ruleForm.value.email.join(',') : undefined
                }).then(res => {
                    ElMessage.success({
                        message: res.msg,
                        duration: 2000,
                        onClose: () => {
                            if (proxy.$route.query.path) {
                                proxy.$router.push({ path: proxy.$route.query.path, query: { c_id: proxy.$route.query.c_id } })
                                return
                            }
                            operateStatus.value = null
                            selectedRule.value = null
                            if (searchFormContentRef.value) {
                                searchFormContentRef.value.refreshData()
                            }
                        }
                    })
                })
            } else {
                isSaveDialogShow.value = true
            }
        }
    })
}

const goBack = () => {
    if (proxy.$route.query.path) {
        proxy.$router.push({ path: proxy.$route.query.path, query: { c_id: proxy.$route.query.c_id } })
        return
    }
    operateStatus.value = null;
    selectedRule.value = null;
    ruleForm.value = {
        name: '',
        time_type: 1,
        start_hour: 0,
        end_hour: 0,
        rule: {
            connector: 'AND',
            conditions: [
                {
                    connector: 'AND',
                    conditions: [{
                        field: '',
                        range: '',
                        operator: '',
                        value: ''
                    }]
                }
            ]
        },
        type: 1,
        description: '',
        ads_level: 'campaign',
        campaigns: [],
        is_notice: true,
        editEmail: false,
        emailInput: '',
        email: []
    }
}

onMounted(async () => {
    await checkAuth()
    if (accountInfo.value && accountInfo.value.fb_user_id) {
        if (proxy.$route.query.type === 'add') {
            addRule()
        }
        if (proxy.$route.query.type === 'edit') {
            editRule({ id: proxy.$route.query.id })
        }
    }
})
</script>

<style lang="less">
.custom-rule-delete-dialog {
    .el-dialog__body {
        padding-top: 5px;
    }

    .delete {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 40px;
            height: 40px;
            margin-bottom: 20px;
        }

        .delete-title {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #262626;
            margin-bottom: 40px;
        }

        .delete-info {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #595959;
            margin-bottom: 40px;
        }

        .el-button+.el-button {
            margin-left: 16px;
        }
    }
}

.custom-rule-copy-dialog {
    &>.el-dialog__header {
        padding: 16px 20px;
        border-bottom: 1px solid #f0f0f0 !important;
    }

    .el-dialog__body {
        padding-top: 5px;
    }

    .el-dialog__footer {
        padding: 0 20px 40px 0 !important;
    }

    .el-button+.el-button {
        margin-left: 16px;
    }

    .copy {
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        &>span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #262626;
            margin-right: 16px;

            &::before {
                content: '*';
                color: #f56c6c;
                margin-right: 4px;
            }
        }

        .el-radio-group {
            margin-top: 16px;
            margin-left: 44px;
        }
    }

    .save {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        margin-top: 24px;
        margin-bottom: 20px;
    }
}

.custom-rule-edit-dialog {
    &>.el-dialog__header {
        padding: 16px 20px;
        border-bottom: 1px solid #f0f0f0 !important;

        .iconfont {
            color: #ffb900;
            font-size: 26px;
            margin-right: 8px;
            vertical-align: bottom;
        }

        span {
            font-weight: 400;
            font-size: 18px;
            color: #262626;
            line-height: 26px;
        }
    }

    .el-dialog__body {
        padding-top: 5px;
    }

    .el-dialog__footer {
        padding: 0 20px 40px 0 !important;
    }

    .el-button+.el-button {
        margin-left: 16px;
    }

    .save {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        margin-top: 24px;
        margin-bottom: 20px;
    }
}
</style>

<style lang="less" scoped>
.custom-rule {
    position: relative;

    :deep(.login-button.el-button) {
        border: 1px solid #2b82ff;
        font-size: 14px;
        font-family: PingFang SC-Medium;
        font-weight: 500;
        color: #2b82ff;
    }

    .login-user {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 16px;

        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
        }

        span {
            line-height: 16px;
            vertical-align: middle;
        }
    }

    :deep(.custom-rule-empty-desc) {
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        color: #595959;

        .el-button {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
        }
    }

    :deep(.search-form) {
        width: 100%;

        .el-form-item {
            margin-right: 20px;
        }
    }

    :deep(.el-row.is-justify-end) {
        display: none;
    }

    :deep(.el-table) {
        .el-table_1_column_1 {
            padding: 0 0 0 20px;

            .cell {
                height: 100%;
                line-height: 65px;
            }
        }

        .el-table__cell {
            padding-left: 20px;
            color: #595959;
        }

        .el-table__body tr:hover>td .tool-icon {
            display: block;
        }

        .el-table__body tr:hover>td .tool-container {
            background-color: #F5F7FA;
        }

        .rule-row {
            display: flex;
            align-items: center;
            position: relative;

            .rule-name {
                display: inline-block;
                width: calc(100% - 160px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .vxe-switch {
                padding: 0;
                margin: 0 16px 0 24px;

                .vxe-switch--button:focus {
                    box-shadow: none !important;
                }
            }
        }

        .tool-container {
            height: 32px;
            gap: 24px;
            z-index: 9;

            .tool-box:hover .tool-icon {
                display: none !important;
            }

            .tool-box:hover .tool-button {
                display: block;
            }

            .tool-box {
                height: 100%;
                display: flex;
                align-items: center;
            }

            .tool-icon {
                cursor: pointer;
                color: #2b82ff;

                .iconfont {
                    font-size: 24px !important;
                }
            }

            .tool-button {
                display: none;
                width: 52px;
                height: 24px;
                background: #DDEBFF;
                border-radius: 2px;
                border: 1px solid #2B82FF;
                font-size: 12px;
                font-family: PingFang SC-Regular;
                color: #2b82ff;
                text-align: center;
                line-height: 24px;
                cursor: pointer;
            }
        }
    }

    .unauth {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .ad-series-empty-desc {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #8c8c8c;

            .el-button {
                color: #2b82ff;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
            }
        }
    }

    .add-rule-container {
        :deep(.el-radio-group) {
            height: 32px;

            .el-radio {
                height: 32px;
            }
        }

        :deep(.el-form-item) {
            margin-bottom: 16px;

            .el-form-item__label {
                width: 120px;
                line-height: 32px;
                padding-right: 0;
                margin-right: 32px;
                color: #262626 !important;
            }

            .el-form-item__content {
                line-height: 32px;
            }
        }

        .title {
            font-family: PingFang SC-Medium;
            font-weight: 500;
            font-size: 14px;
            color: #8C8C8C;
            margin-bottom: 16px;
        }

        .rule-container {
            margin-top: 22px;

            .condition-container {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                min-height: 323px;
                border-radius: 8px;
                border: 1px solid #DCDFE6;
                padding: 20px 8px 24px 8px;
                gap: 16px;
                margin-bottom: 16px;

                .title {
                    font-weight: 400;
                    color: #262626;
                    margin-left: 8px;
                }

                .item {
                    background: #F5F7FA;
                    border-radius: 4px 4px 4px 4px;

                    .rule-apply-level,
                    .rule-operate {
                        margin: 8px 0;

                        :deep(.el-form-item__label) {
                            width: 134px;
                        }
                    }

                    .rule-desc-title {
                        box-sizing: border-box;
                        width: 140px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        padding: 8px;
                        font-size: 14px;
                        cursor: pointer;

                        .iconfont {
                            font-size: 10px;
                        }
                    }

                    .rule-desc-content {
                        font-size: 14px;
                        color: #262626;
                        margin-left: 84px;
                        margin-bottom: 24px;
                    }
                }
            }

            .select-count {
                font-size: 14px;
                color: #8C8C8C;
                margin-left: 16px;
            }

            :deep(.vxe-checkbox .vxe-checkbox--icon) {
                box-shadow: none !important;
            }

            :deep(.vxe-checkbox--label) {
                line-height: 32px;
                color: #262626;
                font-family: PingFangSC-Regular, PingFang SC;
            }

            .email-list {
                .email {
                    font-size: 14px;
                    color: #2B82FF;
                    margin-right: 12px;
                }

                .button {
                    font-size: 14px;
                    color: #2B82FF;
                    cursor: pointer;
                }

                .email-tag {
                    height: 24px;
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 4px;
                    background: #F4F4F5;
                    border: 1px solid #DCDFE6;
                    border-radius: 4px;
                    margin-right: 8px;
                    line-height: unset;

                    span {
                        font-size: 12px;
                        color: #595959;
                        margin-right: 6px;
                    }

                    .iconfont {
                        font-size: 12px;
                        cursor: pointer;
                        color: #8c8c8c;
                    }
                }
            }
        }

        .footer {
            display: flex;
            justify-content: flex-end;
            margin-top: 24px;

            :deep(.el-button+.el-button) {
                margin-left: 24px;
            }
        }
    }
}
</style>